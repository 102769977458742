import { createFileRoute } from '@tanstack/react-router'

import { fetchResume } from '@/repository'
import Builder from '@/form/Builder'
import PdfViewer from '@/viewer/PdfViewer'
import { ResumeProvider } from '@/context/resume-context'
import { checkAuthentication } from '@/lib/authentication'
// Needed to load the templates
import '@/templates'

export const Route = createFileRoute('/resumes/$resumeId')({
  component: Resume,
  beforeLoad: checkAuthentication,
  loader: ({ params: { resumeId } }) => fetchResume(resumeId),
})

function Resume() {
  const { resumeId } = Route.useParams()

  return (
    <ResumeProvider resumeId={resumeId}>
      <div className="flex h-screen">
        <div className="flex-grow">
          <PdfViewer />
        </div>

        <div className="w-96 max-h-full overflow-y-auto antialiased px-4 pb-4 pt-2">
          <Builder />
        </div>
      </div>
    </ResumeProvider>
  )
}
