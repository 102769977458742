import { DiscIcon, ReloadIcon } from '@radix-ui/react-icons'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { useRecoilValue } from 'recoil'

import { Button } from '@/components/ui/button'
import { useResume } from '@/context/resume-context'
import { pdfInstanceState } from '@/store'
import { updateResume, uploadResumeFile } from '@/repository'

const SaveButton: React.FC = () => {
  const [message, setMessage] = useState<JSX.Element | string | null>(null)

  const { resume } = useResume()
  const pdfInstance = useRecoilValue(pdfInstanceState)

  const saveResume = useMutation({
    mutationKey: ['saveResume', resume.id],
    mutationFn: async () => {
      if (!pdfInstance?.blob) {
        console.error('Cant save pdf: no PDF instance or blob')
        return
      }

      await Promise.all([
        uploadResumeFile({
          blob: pdfInstance.blob,
          resumeId: resume.id,
          userId: resume.user_id,
        }),
        updateResume(resume),
      ])
    },
    onSuccess: () => {
      setMessage('Saved!')
      setTimeout(() => setMessage(null), 2000)
    },
    onError: () => {
      setMessage(<span className="text-rose-500">Error saving</span>)
      setTimeout(() => setMessage(null), 2000)
    },
  })

  return (
    <>
      {message && <div className="text-xs text-zinc-400">{message}</div>}
      <Button
        onClick={() => saveResume.mutateAsync()}
        disabled={saveResume.isPending}
      >
        {saveResume.isPending ? (
          <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
        ) : (
          <DiscIcon className="w-4 h-4 mr-2" />
        )}
        Save
      </Button>
    </>
  )
}

export default SaveButton
