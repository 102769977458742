import { JsonForms } from '@jsonforms/react'
import {
  JsonFormsStyleContext,
  vanillaCells,
  vanillaRenderers,
} from '@jsonforms/vanilla-renderers'
import { FC } from 'react'

import ObjectControl, { objectControlTester } from './ObjectControl'
import ResumeSchema from '../schema/ResumeJsonSchema.json'
import { useResume } from '@/context/resume-context'
import FileInputCell, { fileInputCellTester } from './FileInputCell'
import GroupLayout, { groupTester } from './GroupLayout'
import ArrayControlRenderer, {
  arrayControlTester,
} from './ArrayControlRenderer'
import {
  BackpackIcon,
  BookmarkIcon,
  FileIcon,
  HeartIcon,
  HobbyKnifeIcon,
  Link2Icon,
  PersonIcon,
  RocketIcon,
  RulerSquareIcon,
  SpeakerLoudIcon,
  StarIcon,
  TargetIcon,
} from '@radix-ui/react-icons'

interface Props {}

const styleContextValue = {
  styles: [
    {
      name: 'control',
      classNames: ['mb-6'],
    },
    {
      name: 'control.input',
      classNames: [
        'flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
      ],
    },
    {
      name: 'control.label',
      classNames: [
        'text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
      ],
    },
    {
      name: 'input.description',
      classNames: ['mt-2 text-sm text-gray-500 dark:text-gray-400'],
    },
    {
      name: 'group.label',
      classNames: ['text-xl font-bold dark:text-white mb-3'],
    },
    {
      name: 'array.button',
      classNames: [
        'text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-3 py-2 text-xs me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700',
      ],
    },
    {
      name: 'array.table.label',
      classNames: ['text-sm font-medium text-gray-900 dark:text-white'],
    },
    {
      name: 'array.table.button',
      classNames: [
        'text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-3 py-2 text-xs me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700',
      ],
    },
  ],
}

// Auto generate UI Schema
// console.log(Generate.uiSchema(ResumeSchema.properties.basics))

// the type should be: UISchemaElement
const uischema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Group',
      label: 'Basics',
      options: {
        icon: <PersonIcon />,
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/basics/properties/name',
        },
        {
          type: 'Control',
          scope: '#/properties/basics/properties/label',
        },
        {
          type: 'Control',
          scope: '#/properties/basics/properties/image',
        },
        {
          type: 'Control',
          scope: '#/properties/basics/properties/email',
        },
        {
          type: 'Control',
          scope: '#/properties/basics/properties/phone',
        },
        {
          type: 'Control',
          scope: '#/properties/basics/properties/url',
        },
        {
          type: 'Control',
          scope: '#/properties/basics/properties/summary',
          options: {
            multi: true,
          },
        },
        {
          type: 'Control',
          scope: '#/properties/basics/properties/location',
        },
        {
          type: 'Control',
          scope: '#/properties/basics/properties/profiles',
        },
      ],
    },
    {
      type: 'Group',
      label: 'Work',
      options: {
        icon: <BackpackIcon />,
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/work',
        },
      ],
    },
    {
      type: 'Group',
      label: 'Education',
      options: {
        icon: <RulerSquareIcon />,
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/education',
        },
      ],
    },
    {
      type: 'Group',
      label: 'Certificates',
      options: {
        icon: <BookmarkIcon />,
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/certificates',
        },
      ],
    },
    {
      type: 'Group',
      label: 'Projects',
      options: {
        icon: <RocketIcon />,
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/projects',
        },
      ],
    },
    {
      type: 'Group',
      label: 'Skills',
      options: {
        icon: <HobbyKnifeIcon />,
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/skills',
        },
      ],
    },
    {
      type: 'Group',
      label: 'Languages',
      options: {
        icon: <SpeakerLoudIcon />,
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/languages',
        },
      ],
    },
    {
      type: 'Group',
      label: 'Interest',
      options: {
        icon: <StarIcon />,
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/interests',
        },
      ],
    },
    {
      type: 'Group',
      label: 'Awards',
      options: {
        icon: <TargetIcon />,
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/awards',
        },
      ],
    },
    {
      type: 'Group',
      label: 'Publications',
      options: {
        icon: <FileIcon />,
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/publications',
        },
      ],
    },
    {
      type: 'Group',
      label: 'References',
      options: {
        icon: <Link2Icon />,
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/references',
        },
      ],
    },
    {
      type: 'Group',
      label: 'Volunteer',
      options: {
        icon: <HeartIcon />,
      },
      elements: [
        {
          type: 'Control',
          scope: '#/properties/volunteer',
        },
      ],
    },
  ],
}

const renderers = [
  ...vanillaRenderers,
  { tester: objectControlTester, renderer: ObjectControl },
  { tester: groupTester, renderer: GroupLayout },
  { tester: arrayControlTester, renderer: ArrayControlRenderer },
]

const cells = [
  ...vanillaCells,
  { tester: fileInputCellTester, cell: FileInputCell },
]

const ResumeForm: FC<Props> = () => {
  const { resume, setResume } = useResume()

  return (
    <JsonFormsStyleContext.Provider value={styleContextValue}>
      <JsonForms
        schema={ResumeSchema}
        data={resume?.resume}
        renderers={renderers}
        cells={cells}
        uischema={uischema}
        onChange={({ data }) => {
          setResume({ ...resume, resume: data })
        }}
      />
    </JsonFormsStyleContext.Provider>
  )
}

export default ResumeForm
