import { RouterProvider, createRouter } from '@tanstack/react-router'
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { sessionState } from './store'
import { supabase } from './supabase'

// Route tree generated automatically by TanStack Router
import { routeTree } from './routeTree.gen'

const router = createRouter({ routeTree, context: { auth: null } })

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

export default function App() {
  const [session, setSession] = useRecoilState(sessionState)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [])

  // Wait for the session to be loaded, as undefined means we're still loading
  // while null means the user is not logged in
  if (session === undefined) {
    return <div>Loading...</div>
  }

  return <RouterProvider router={router} context={{ auth: session }} />
}
