import { Link } from '@tanstack/react-router'
import { useQuery } from '@tanstack/react-query'
import { PersonIcon } from '@radix-ui/react-icons'
import dayjs from 'dayjs'
import React from 'react'

import { ResumeTableRow } from '@/types'
import { cn } from '@/lib/utils'
import { useSession } from '@/hooks/use-session'
import ResumeMenu from '../ResumeMenu'
import { createSignedUrl } from '@/repository'

interface Props {
  resume: Omit<ResumeTableRow, 'resume' | 'theme'>
}

const SkeletonDocument: React.FC<{ loading?: boolean }> = ({
  loading = true,
}) => {
  const animation = loading && 'animate-pulse'

  return (
    <div className="p-8 space-y-2">
      <div className={cn('h-4 bg-zinc-200 w-3/4 rounded-sm', animation)} />
      <div className={cn('h-3 bg-zinc-200 w-1/2 rounded-sm', animation)} />
      <div className="h-4" />
      <div className={cn('h-2 bg-zinc-200 rounded-sm', animation)} />
      <div className={cn('h-2 bg-zinc-200 rounded-sm', animation)} />
      <div className={cn('h-2 bg-zinc-200 rounded-sm', animation)} />
      <div className={cn('h-2 bg-zinc-200 rounded-sm', animation)} />
      <div className={cn('h-2 bg-zinc-200 rounded-sm', animation)} />
      <div className={cn('h-2 bg-zinc-200 rounded-sm', animation)} />
    </div>
  )
}

const ResumeCard: React.FC<Props> = ({ resume }) => {
  const { session } = useSession()

  const pdfQuery = useQuery({
    queryKey: ['download-pdf', resume.id],
    queryFn: () => createSignedUrl(resume),
    retry(failureCount, error) {
      return error.message !== 'Object not found' && failureCount < 3
    },
  })

  const pdfUrl = pdfQuery.data?.signedUrl

  return (
    <Link
      to="/resumes/$resumeId"
      params={{ resumeId: resume.id }}
      className="group flex flex-col rounded-sm overflow-hidden bg-white shadow-lg aspect-[3/4] cursor-pointer hover:shadow-xl transition-shadow"
    >
      <div className="relative overflow-hidden flex-grow bg-gradient-to-br from-indigo-400 to-cyan-400">
        <div className="w-3/4 h-full mt-6 bg-white mx-auto">
          {pdfQuery.isLoading || pdfQuery.isError ? (
            <SkeletonDocument loading={pdfQuery.isLoading} />
          ) : (
            <iframe
              className="w-full h-full border-none"
              src={pdfUrl ? pdfUrl + '#toolbar=0' : undefined}
            />
          )}
        </div>

        <div
          className="absolute top-0 bottom-0 right-0 left-0 flex justify-end items-end p-2"
          style={{
            backgroundImage:
              'radial-gradient(100% 20px at 50% 100%, #b9b9b963, transparent)',
          }}
        >
          {session?.user.id !== resume.user_id && <PersonIcon />}
        </div>
      </div>

      <div className="flex">
        <div className="p-4 flex-grow">
          <div className="font-medium text-sm text-zinc-700 mb-0.5">
            {resume.name}
          </div>
          <div className="text-xs text-zinc-400">
            Updated {dayjs(resume.updated_at).fromNow()}
          </div>
        </div>

        <div className="py-4 pr-2 opacity-0 group-hover:opacity-100 transition-opacity">
          <ResumeMenu resume={resume} />
        </div>
      </div>
    </Link>
  )
}

export default ResumeCard
