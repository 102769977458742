import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { AnswerQuestionsSchema as FunctionSchema } from '@/schema/AiSuggestionsSchema'
import SelectField from '@/components/form/SelectField'
import TextareaField from '@/components/form/TextareaField'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { useResume } from '@/context/resume-context'
import { aiAnswerQuestion } from '@/repository'
import ResponsePane from './ResponsePane'

const AnswerQuestionsSchema = FunctionSchema.omit({ resume: true })

const AnswerQuestions: React.FC = () => {
  const { resume } = useResume()

  const form = useForm<z.infer<typeof AnswerQuestionsSchema>>({
    defaultValues: {
      perspective: '1st person',
    },
    resolver: zodResolver(AnswerQuestionsSchema),
  })

  const askQuestion = useMutation(aiAnswerQuestion())

  return (
    <div className="grid grid-cols-2 gap-4">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(async (data) => {
            await askQuestion.mutateAsync({ ...data, resume: resume.resume })
          })}
          className="space-y-4"
        >
          <TextareaField
            form={form}
            label="Job Description"
            name="jobDescription"
            placeholder="This job requires you to have experience in..."
          />

          <TextareaField
            form={form}
            label="Question"
            name="question"
            placeholder="Why are you a match for this position?"
          />

          <SelectField
            form={form}
            label="Perspective"
            name="perspective"
            items={[
              { label: '1st person', value: '1st person' },
              { label: '3rd person', value: '3rd person' },
            ]}
          />

          <div className="text-right">
            <Button
              type="submit"
              disabled={askQuestion.isPending}
              loading={askQuestion.isPending}
            >
              Answer
            </Button>
          </div>
        </form>
      </Form>

      <ResponsePane query={askQuestion} />
    </div>
  )
}

export default AnswerQuestions
