import { FieldValues, Path, UseFormReturn } from 'react-hook-form'

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { cn } from '@/lib/utils'

type Props = Omit<React.InputHTMLAttributes<HTMLSelectElement>, 'form'>

interface ItemProps {
  label: string
  value: string
}

const SelectField = <TFieldValues extends FieldValues = FieldValues>({
  form,
  StartIcon,
  className,
  name,
  label,
  items,
  ...props
}: Props & {
  label: string
  description?: string
  name: Path<TFieldValues>
  form: UseFormReturn<TFieldValues>
  items: ItemProps[]
  StartIcon?: JSX.ElementType
}) => (
  <FormField
    control={form.control}
    name={name}
    render={({ field }) => (
      <FormItem>
        <FormLabel>{label || name}</FormLabel>

        <div className="relative">
          {StartIcon && (
            <StartIcon className="absolute left-2 top-2.5 h-5 w-5 text-muted-foreground" />
          )}
          <Select
            onValueChange={field.onChange}
            defaultValue={field.value}
            value={field.value}
            disabled={props.disabled}
          >
            <FormControl>
              <SelectTrigger className={cn(className, StartIcon && 'pl-8')}>
                <SelectValue placeholder={props.placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {items.map((item) => (
                <SelectItem key={item.value} value={item.value}>
                  {item.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        {props.description && (
          <FormDescription>{props.description}</FormDescription>
        )}
        <FormMessage />
      </FormItem>
    )}
  />
)

export default SelectField
