import { z } from 'zod'

export const AnswerQuestionsSchema = z.object({
  question: z.string().min(10).max(500),
  jobDescription: z.string().optional().nullable().default(''),
  perspective: z.enum(['1st person', '3rd person']).default('1st person'),
  resume: z.any(),
})

export const TailorResumeSchema = z.object({
  jobPosition: z.string().min(5),
  jobDescription: z.string().optional().nullable(),
  resume: z.any(),
})

export const FixGrammarSchema = z.object({
  resume: z.any(),
})

export const InputSchema = z
  .object({
    suggestionType: z.literal('answer-question'),
    input: AnswerQuestionsSchema,
  })
  .or(
    z.object({
      suggestionType: z.literal('tailor-resume'),
      input: TailorResumeSchema,
    }),
  )
  .or(
    z.object({
      suggestionType: z.literal('fix-grammar'),
      input: FixGrammarSchema,
    }),
  )
