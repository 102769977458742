import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const objectDeepMerge = (target: any, source: any) => {
  for (const key in source) {
    if (source[key] instanceof Object)
      Object.assign(source[key], objectDeepMerge(target[key], source[key]))
  }
  Object.assign(target || {}, source)
  return target
}
