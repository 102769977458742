import { ResumeTemplateProps, ThemeData, ThemeOptions } from '@/types'

export interface TemplateRegistryEntry<T extends ThemeData = ThemeData> {
  id: string
  template: React.FC<ResumeTemplateProps<T>>
  theme: ThemeOptions
}

const registry: Record<string, TemplateRegistryEntry> = {}

export const registerTemplate = <T extends ThemeData>({
  template,
  theme,
}: Omit<TemplateRegistryEntry<T>, 'id'>) => {
  if (!template.displayName) {
    console.error(
      'Resume template components must have a display name',
      template,
    )
    return
  }

  registry[template.displayName] = {
    id: template.displayName,
    template: template as React.FC<ResumeTemplateProps<ThemeData>>,
    theme,
  }
}

export const findTemplateById = (
  id: string,
): TemplateRegistryEntry | undefined => {
  return registry[id]
}
