import { usePDF } from '@react-pdf/renderer'
import { useDebounce } from '@uidotdev/usehooks'
import { FC, useEffect } from 'react'
import { useSetRecoilState } from 'recoil'

import { pdfInstanceState } from '@/store'
import { findTemplateById } from '@/templates/registry'
import { getThemeDefaultValues } from '@/designer'
import { useResume } from '@/context/resume-context'
import ResumeToolbar from './ResumeToolbar'

interface Props {}

const defaultResume = {
  basics: {},
  work: [],
  skills: [],
  languages: [],
  certificates: [],
  education: [],
}

const DOCUMENT_UPDATE_DEBOUNCE = 1000

const PdfViewer: FC<Props> = () => {
  const { resume } = useResume()
  const setPdfInstance = useSetRecoilState(pdfInstanceState)

  const template = findTemplateById(resume!.template)
  const defaultTheme = getThemeDefaultValues(template!.theme)

  // Debounce the re-render of the PDF
  const debouncedResume = useDebounce(resume, DOCUMENT_UPDATE_DEBOUNCE)

  const [pdfInstance, updatePdf] = usePDF()

  useEffect(() => {
    if (template) {
      updatePdf(
        <template.template
          resume={debouncedResume?.resume || defaultResume}
          theme={{ ...defaultTheme, ...debouncedResume?.theme } || defaultTheme}
        />,
      )
    }
  }, [debouncedResume, updatePdf])

  useEffect(() => {
    setPdfInstance(pdfInstance)
  }, [setPdfInstance, pdfInstance])

  if (!resume || !Object.keys(resume).length || !pdfInstance.url) {
    return
  }

  return (
    <div
      // the background color is the same as browser the PDF reader
      style={{ backgroundColor: '#525659', height: 'calc(100% - 53px)' }}
    >
      <ResumeToolbar />
      <iframe className="w-full h-full" src={pdfInstance.url + '#toolbar=0'} />
    </div>
  )
}

export default PdfViewer
