import { CopyIcon } from '@radix-ui/react-icons'
import { UseMutationResult } from '@tanstack/react-query'

import { Button } from '@/components/ui/button'

interface Props<T = string> extends React.PropsWithChildren {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query: UseMutationResult<T, any, any>
}

const CopyButton = ({ text }: { text: string }) => (
  <Button
    variant="secondary"
    onClick={() => navigator.clipboard.writeText(text)}
    className="absolute top-2 right-2 opacity-0 group-hover:opacity-75"
  >
    <CopyIcon className="mr-2" />
    Copy the answer
  </Button>
)

const ResponsePane: React.FC<Props> = ({ children, query }) => {
  return (
    <div className="flex flex-col space-y-3">
      <div className="font-medium text-sm">
        {query.isPending ? 'AI is thinking...' : 'Answer'}
      </div>

      {children || (
        <div className="relative group bg-muted rounded-md p-4 flex-grow overflow-y-auto">
          {query.isSuccess && <CopyButton text={query.data || ''} />}
          <div className="text-sm text-muted-foreground">
            {query.data || 'The answer will appear here...'}
          </div>
        </div>
      )}
    </div>
  )
}

export default ResponsePane
