import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { deleteResume } from '@/repository'
import { useQueryClient } from '@tanstack/react-query'

interface Props {
  resume: {
    id: string
    user_id: string
    name: string
  }
  onDelete?: () => void
}

const DeleteResumeDialog: React.FC<Props> = ({ resume, onDelete }) => {
  const queryClient = useQueryClient()

  return (
    <AlertDialogContent onClick={(event) => event.stopPropagation()}>
      <AlertDialogHeader>
        <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
        <AlertDialogDescription>
          This action cannot be undone. This will permanently delete your
          resume.
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel>Cancel</AlertDialogCancel>
        <AlertDialogAction
          onClick={async () => {
            await deleteResume(resume)
            queryClient.invalidateQueries({
              queryKey: ['resumes'],
            })

            if (onDelete) {
              onDelete()
            }
          }}
        >
          Delete
        </AlertDialogAction>
      </AlertDialogFooter>
    </AlertDialogContent>
  )
}

export default DeleteResumeDialog
