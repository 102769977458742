import { Session } from '@supabase/supabase-js'
import { atom } from 'recoil'

import { UsePDFInstance } from '@react-pdf/renderer'

export const sessionState = atom<Session | null | undefined>({
  key: 'sessionState',
  default: undefined,
})

export const pdfInstanceState = atom<UsePDFInstance | null>({
  key: 'pdfInstanceState',
  default: null,
})
