import {
  and,
  CellProps,
  formatIs,
  RankedTester,
  rankWith,
  schemaTypeIs,
  uiTypeIs,
} from '@jsonforms/core'
import { withJsonFormsCellProps } from '@jsonforms/react'
import merge from 'lodash/merge'
import {
  VanillaRendererProps,
  withVanillaCellProps,
} from '@jsonforms/vanilla-renderers'
import { useRecoilValue } from 'recoil'

import { uploadGenericFile } from '@/repository'
import { sessionState } from '@/store'
import FileManager from '@/components/FileManager'
import { useQueryClient } from '@tanstack/react-query'

export const FileInputCell = (props: CellProps & VanillaRendererProps) => {
  const session = useRecoilValue(sessionState)

  const {
    config,
    data,
    className,
    id,
    enabled,
    uischema,
    schema,
    path,
    handleChange,
  } = props
  const maxLength = schema.maxLength
  const appliedUiSchemaOptions = merge({}, config, uischema.options)
  const queryClient = useQueryClient()

  return (
    <div className="flex gap-4">
      <div className="relative h-24 w-24 flex-shrink-0 border rounded-sm overflow-hidden">
        {data && (
          <img
            src={data}
            alt="preview"
            className="h-full w-full object-cover"
          />
        )}
      </div>

      <div className="flex flex-col items-stretch justify-between">
        <input
          type="file"
          onChange={async (ev) => {
            const file = ev.target.files?.[0]

            if (file) {
              const response = await uploadGenericFile({
                blob: file,
                name: file.name,
                userId: session!.user.id,
              })

              handleChange(path, response.url)

              // Invalidate the files query to update the files manager
              await queryClient.invalidateQueries({
                queryKey: ['files'],
              })
            } else {
              handleChange(path, '')
            }
          }}
          className={className}
          accept="image/png, image/jpeg"
          id={id}
          disabled={!enabled}
          autoFocus={appliedUiSchemaOptions.focus}
          placeholder={appliedUiSchemaOptions.placeholder}
          maxLength={appliedUiSchemaOptions.restrict ? maxLength : undefined}
          size={appliedUiSchemaOptions.trim ? maxLength : undefined}
        />

        <FileManager onSelect={(url) => handleChange(path, url)} />
      </div>
    </div>
  )
}

/**
 * Default tester for text-based/string controls.
 * @type {RankedTester}
 */
export const fileInputCellTester: RankedTester = rankWith(
  2,
  and(uiTypeIs('Control'), schemaTypeIs('string'), formatIs('image')),
)

export default withJsonFormsCellProps(withVanillaCellProps(FileInputCell))
