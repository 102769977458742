import { FieldValues, Path, UseFormReturn } from 'react-hook-form'

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { cn } from '@/lib/utils'

type InputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'form'>

const InputField = <TFieldValues extends FieldValues = FieldValues>({
  form,
  StartIcon,
  EndIcon,
  className,
  ...props
}: InputProps & {
  label: string
  description?: string
  name: Path<TFieldValues>
  form: UseFormReturn<TFieldValues>
  StartIcon?: JSX.ElementType
  EndIcon?: JSX.ElementType
}) => (
  <FormField
    control={form.control}
    name={props.name}
    render={({ field }) => (
      <FormItem className={className}>
        <FormLabel>{props.label}</FormLabel>
        <FormControl>
          <div className="relative">
            {StartIcon && (
              <StartIcon className="absolute left-2 top-2.5 h-5 w-5 text-muted-foreground" />
            )}
            <Input
              className={cn(StartIcon && 'pl-8', EndIcon && 'pr-8')}
              {...props}
              {...field}
            />
            {EndIcon && (
              <EndIcon className="absolute right-2 top-2.5 h-5 w-5 text-muted-foreground" />
            )}
          </div>
        </FormControl>
        {props.description && (
          <FormDescription>{props.description}</FormDescription>
        )}
        <FormMessage />
      </FormItem>
    )}
  />
)

export default InputField
