import { PlusIcon } from '@radix-ui/react-icons'
import { useQuery } from '@tanstack/react-query'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { useState } from 'react'

import ResumeCard from '@/components/ui/ResumeCard'
import { Button } from '@/components/ui/button'
import { createResume, listResumes } from '@/repository'
import { checkAuthentication } from '@/lib/authentication'
import UserMenu from '@/components/UserMenu'
import { Switch } from '@/components/ui/switch'
import { Label } from '@/components/ui/label'
import { useSession } from '@/hooks/use-session'

export const Route = createFileRoute('/')({
  component: Index,
  beforeLoad: checkAuthentication,
})

function Index() {
  const { isAdmin, session } = useSession()
  const [seeAllResumes, setSeeAllResumes] = useState(false)

  const navigate = useNavigate()
  const query = useQuery({
    queryKey: ['resumes', seeAllResumes ? undefined : session!.user.id],
    queryFn: ({ queryKey }) => listResumes(queryKey[1]),
  })

  if (query.isLoading || query.isFetching) {
    return <div>Loading...</div>
  }

  if (query.isError) {
    return <div>Error: {query.error.message}</div>
  }

  const resumes = query.data

  return (
    <>
      <nav className="h-10">
        <div className="container flex gap-4 pt-2 items-center">
          <img src="/logo.png" alt="Bloom Logo" className="h-10" />
          <span className='text-zinc-500 -ml-2 tracking-tight'>BLOOM</span>

          <div className="flex-grow" />

          {isAdmin && (
            <div className="flex items-center space-x-2">
              <Switch
                id="resumes-filter"
                checked={seeAllResumes}
                onCheckedChange={(checked) => {
                  setSeeAllResumes(checked)
                }}
              />
              <Label htmlFor="resumes-filter">See all</Label>
            </div>
          )}

          <Button
            onClick={async () => {
              const resume = await createResume()

              navigate({
                to: `/resumes/$resumeId`,
                params: { resumeId: resume.id },
              })
            }}
          >
            <PlusIcon className="mr-2 h-4 w-4" />
            New resume
          </Button>

          <UserMenu />
        </div>
      </nav>

      <div className="container py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {resumes!.map((resume) => (
            <ResumeCard key={resume.id} resume={resume} />
          ))}
        </div>

        {resumes!.length === 0 && (
          <div className="text-center text-zinc-600">
            <div>
              You don't have any Resume yet!
              <br />
              Create your first one to get started.
            </div>
          </div>
        )}
      </div>
    </>
  )
}
