import { ArrowLeftIcon } from '@radix-ui/react-icons'
import { useState } from 'react'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import AnswerQuestions from './ai/AnswerQuestions'
import TailorResume from './ai/TailorResume'
import { Card, CardDescription, CardHeader, CardTitle } from './ui/card'
// import FixGrammar from './ai/FixGrammar'

interface AiAction {
  name: string
  icon: React.ReactNode
  description: string
  component: React.FC<{ closeDialog: () => void }>
}

const AI_ACTIONS: AiAction[] = [
  // {
  //   name: 'Fix grammar',
  //   icon: '📝',
  //   description: 'Fix grammar and spelling mistakes',
  //   component: FixGrammar,
  // },
  {
    name: 'Answer questions',
    icon: '🙋‍♀️',
    description:
      'Answer questions for a job application based on your resume, e.g create a cover letter, why are you a good fit?',
    component: AnswerQuestions,
  },
  {
    name: 'Tailor resume',
    icon: '🪡',
    description: 'Tailor your resume for a specific job position',
    component: TailorResume,
  },
]

const AiDialog: React.FC = () => {
  const [open, setOpen] = useState(false)
  const [currentAction, setAction] = useState<string | null>(null)

  const action = AI_ACTIONS.find((a) => a.name === currentAction)

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="secondary">
          <span className="mr-2">🔮</span>
          Smart Assistant
        </Button>
      </DialogTrigger>
      <DialogContent className="flex flex-col md:min-w-[600px] lg:min-w-[800px] max-h-[90vh] overflow-hidden">
        <DialogHeader>
          {currentAction ? (
            <DialogTitle className="flex items-center gap-2">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => {
                  setAction(null)
                }}
              >
                <ArrowLeftIcon />
              </Button>
              <span>{currentAction}</span>
            </DialogTitle>
          ) : (
            <DialogTitle>🔮&nbsp;&nbsp;Improve your Resume</DialogTitle>
          )}
        </DialogHeader>

        <div className="overflow-y-auto">
          {!currentAction && (
            <div className="grid gap-4 grid-cols-1 md:grid-cols-3 py-4">
              {AI_ACTIONS.map((action) => (
                <Card
                  key={action.name}
                  className="shadow-none cursor-pointer hover:border-primary transition-colors"
                  onClick={() => {
                    setAction(action.name)
                  }}
                >
                  <CardHeader>
                    <CardTitle className="flex items-center mb-2">
                      <div className="mr-2">{action.icon}</div>
                      {action.name}
                    </CardTitle>
                    <CardDescription>{action.description}</CardDescription>
                  </CardHeader>
                </Card>
              ))}
            </div>
          )}

          {currentAction && action && (
            <action.component closeDialog={() => setOpen(false)} />
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default AiDialog
