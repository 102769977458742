import { FC } from 'react'
import { BlendingModeIcon, Pencil1Icon } from '@radix-ui/react-icons'

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import ResumeForm from '@/form/ResumeForm'
import ThemeDesigner from '@/designer'
import { findTemplateById } from '@/templates/registry'
import { useResume } from '@/context/resume-context'

interface Props {}

const Builder: FC<Props> = () => {
  const { resume } = useResume()
  const template = findTemplateById(resume!.template)

  return (
    <Tabs defaultValue="content">
      <TabsList className="grid w-full grid-cols-2 sticky top-0 z-20">
        <TabsTrigger value="content">
          <Pencil1Icon className="mr-2 w-4 h-4" />
          Content
        </TabsTrigger>

        <TabsTrigger value="style">
          <BlendingModeIcon className="mr-2 w-4 h-4" />
          Style
        </TabsTrigger>
      </TabsList>

      <TabsContent value="content">
        <ResumeForm />
      </TabsContent>

      <TabsContent value="style">
        <ThemeDesigner theme={template!.theme} />
      </TabsContent>
    </Tabs>
  )
}

export default Builder
