import { zodResolver } from '@hookform/resolvers/zod'
import { CheckIcon, Cross2Icon, Pencil1Icon } from '@radix-ui/react-icons'
import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'

import { Form, FormControl, FormField, FormItem } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { updateResume } from '@/repository'
import { useResume } from '@/context/resume-context'

const FormSchema = z.object({
  name: z.string().min(2),
})

const NameForm: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { resume, setResume } = useResume()

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: resume.name,
    },
  })

  const onSubmit: SubmitHandler<z.infer<typeof FormSchema>> = async (data) => {
    const updatedResume = { ...resume, name: data.name }

    await updateResume(updatedResume)
    setResume(updatedResume)

    onClose()
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex gap-1 items-center"
      >
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input placeholder="My resume" {...field} />
              </FormControl>
            </FormItem>
          )}
        />

        <Button type="submit" size="icon" variant="ghost">
          <CheckIcon className="w-4 h-4" />
        </Button>

        <Button
          type="reset"
          size="icon"
          variant="ghost"
          onClick={() => {
            form.reset()
            onClose()
          }}
        >
          <Cross2Icon className="w-4 h-4" />
        </Button>
      </form>
    </Form>
  )
}

const NameEditor: React.FC = () => {
  const { resume } = useResume()
  const [isEditing, setIsEditing] = useState(false)

  return isEditing ? (
    <NameForm onClose={() => setIsEditing(false)} />
  ) : (
    <div className="group font-medium flex gap-2 items-center">
      <div>{resume.name}</div>
      <Pencil1Icon
        className="w-4 h-4 opacity-0 text-zinc-500 hover:text-zinc-700 group-hover:opacity-100 transition-opacity duration-200 cursor-pointer"
        onClick={() => setIsEditing(true)}
      />
    </div>
  )
}

export default NameEditor
