import {
  CopyIcon,
  DotsVerticalIcon,
  DownloadIcon,
  ReloadIcon,
  TrashIcon,
} from '@radix-ui/react-icons'
import { useMutation } from '@tanstack/react-query'
import { useNavigate, useRouterState } from '@tanstack/react-router'
import dayjs from 'dayjs'

import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { createSignedUrl, duplicateResumeById } from '@/repository'
import DeleteResumeDialog from './DeleteResumeDialog'
import { AlertDialog, AlertDialogTrigger } from './ui/alert-dialog'

interface Props {
  resume: {
    id: string
    user_id: string
    name: string
  }
}

const ResumeMenu: React.FC<Props> = ({ resume }) => {
  const duplicateResume = useMutation(duplicateResumeById())
  const routerState = useRouterState()
  const navigate = useNavigate()

  const isResumePage = routerState.location.pathname.includes('/resumes/')

  return (
    <AlertDialog>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon">
            <DotsVerticalIcon />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuGroup>
            <DropdownMenuItem
              onClick={async (event) => {
                event.stopPropagation()

                const date = dayjs().format('D MMM YYYY')
                const filename = `${resume.name} - ${date}.pdf`
                const { signedUrl } = await createSignedUrl(resume, filename)

                const link = document.createElement('a')
                link.href = signedUrl
                link.click()
              }}
            >
              <DownloadIcon className="w-4 h-4 mr-2" />
              Download PDF
            </DropdownMenuItem>

            <DropdownMenuItem
              disabled={duplicateResume.isPending}
              onClick={async (event) => {
                event.stopPropagation()

                const newResume = await duplicateResume.mutateAsync({
                  resumeId: resume.id,
                })

                navigate({
                  to: `/resumes/$resumeId`,
                  params: { resumeId: newResume.id },
                })
              }}
            >
              {!duplicateResume.isPending ? (
                <CopyIcon className="w-4 h-4 mr-2" />
              ) : (
                <ReloadIcon className="w-4 h-4 animate-spin" />
              )}
              Duplicate
            </DropdownMenuItem>

            <DropdownMenuSeparator />

            <AlertDialogTrigger asChild>
              <DropdownMenuItem onClick={(event) => event.stopPropagation()}>
                <TrashIcon className="w-4 h-4 mr-2 text-destructive" />
                Delete
              </DropdownMenuItem>
            </AlertDialogTrigger>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>

      <DeleteResumeDialog
        resume={resume}
        onDelete={() => {
          if (isResumePage) {
            navigate({ to: '/' })
          }
        }}
      />
    </AlertDialog>
  )
}

export default ResumeMenu
