import { ArrowLeftIcon } from '@radix-ui/react-icons'
import { Link } from '@tanstack/react-router'

import { Button } from '@/components/ui/button'
import NameEditor from '@/form/NameEditor'
import SaveButton from '@/form/SaveButton'
import { useResume } from '@/context/resume-context'
import AiDialog from '@/components/AiDialog'
import ResumeMenu from '@/components/ResumeMenu'

const ResumeToolbar: React.FC = () => {
  const { resume } = useResume()

  return (
    <nav className="flex items-center bg-white px-4 py-2 gap-4 border-b">
      <Button size="icon" variant="ghost" asChild>
        <Link to="/">
          <ArrowLeftIcon />
        </Link>
      </Button>

      <NameEditor />

      <div className="flex-grow" />

      <AiDialog />

      <SaveButton />

      <ResumeMenu resume={resume} />
    </nav>
  )
}

export default ResumeToolbar
