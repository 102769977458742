import { useQuery, useQueryClient } from '@tanstack/react-query'
import { PropsWithChildren, createContext, useContext } from 'react'

import { fetchResume } from '@/repository'
import { ResumeTableRow } from '@/types'

interface ResumeContextProps {
  resume: ResumeTableRow
  setResume: React.Dispatch<React.SetStateAction<ResumeTableRow>>
}

const ResumeContext = createContext<ResumeContextProps>({
  resume: {} as ResumeTableRow,
  setResume: () => {},
})

interface Props extends PropsWithChildren {
  resumeId: string
}

export const ResumeProvider: React.FC<Props> = ({ resumeId, children }) => {
  const query = useQuery({
    queryKey: ['resume', resumeId],
    queryFn: () => fetchResume(resumeId),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  })
  const queryClient = useQueryClient()

  if (query.isLoading || query.isFetching) return <div>Loading...</div>
  if (query.isError || !query.data) return <div>Error</div>

  return (
    <ResumeContext.Provider
      value={{
        resume: query.data,
        setResume: (resume) => {
          queryClient.setQueryData(['resume', resumeId], resume)
        },
      }}
    >
      {children}
    </ResumeContext.Provider>
  )
}

export const useResume = () => useContext(ResumeContext)
