import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Link,
} from '@react-pdf/renderer'
import { PropsWithChildren, createContext, useContext } from 'react'
import colors from 'tailwindcss/colors'

import {
  Project,
  Resume,
  ResumeTemplateProps,
  ThemeData,
  ThemeOptions,
  Work,
} from '@/types'
import { beautifyUrl, getLogoByURL } from './helpers'

interface BrusselsTheme extends ThemeData {
  asideBackground: string
  asideBorderWidth: number
  asideBorderColor: string
  colorPrimary: string
  colorTextSecondary: string
  imageRadius: number
  imageSize: number
}

export const themeOptions: ThemeOptions<BrusselsTheme> = {
  asideBackground: { defaultValue: '#47394e0f', label: 'Background color' },
  asideBorderWidth: { defaultValue: 1, min: 1, max: 10, label: 'Border width' },
  asideBorderColor: { defaultValue: '#eae0e7', label: 'Border color' },
  colorPrimary: { label: 'Text accent color', defaultValue: '#891797' },
  colorTextSecondary: {
    label: 'Text secondary color',
    defaultValue: '#918c8c',
  },
  imageRadius: {
    label: 'Image shape',
    options: [
      { text: 'Square', value: 0 },
      { text: 'Rounded (small)', value: 8 },
      { text: 'Rounded', value: 16 },
      { text: 'Circular', value: 1000 },
    ],
    defaultValue: 1000,
  },
  imageSize: {
    label: 'Image size',
    defaultValue: 50,
    min: 35,
    max: 70,
    step: 5,
  },
}

const getDefaultThemeValues = <T extends ThemeData<string>>(
  options: ThemeOptions<T>,
) => {
  const entries = Object.entries(options).map(([key, option]) => [
    key,
    option.defaultValue,
  ])

  return Object.fromEntries(entries) as T
}

Font.register({
  family: 'Montserrat',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/fontsource/fonts/montserrat@latest/latin-400-normal.ttf',
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: 'https://cdn.jsdelivr.net/fontsource/fonts/montserrat@latest/latin-700-normal.ttf',
      fontStyle: 'normal',
      fontWeight: 700,
    },
    {
      src: 'https://cdn.jsdelivr.net/fontsource/fonts/montserrat@latest/latin-900-normal.ttf',
      fontStyle: 'normal',
      fontWeight: 900,
    },
  ],
})

Font.registerHyphenationCallback((word) => [word])

const buildStyles = (theme: BrusselsTheme) =>
  StyleSheet.create({
    page: {
      fontFamily: 'Montserrat',
      fontSize: 9,
      lineHeight: 1.5,
      color: '#404040',
      flexDirection: 'row',
    },
    main: {
      flexGrow: 1,
      padding: 20,
      width: '67%',
    },
    side: {
      flexBasis: '33%',
      padding: 20,
      backgroundColor: theme.asideBackground,
      borderLeftWidth: theme.asideBorderWidth,
      borderLeftColor: theme.asideBorderColor,
      borderLeftStyle: 'solid',
      flexDirection: 'column',
      alignItems: 'stretch',
    },
    title: { color: colors.stone[600], fontWeight: 700 },
    subheader: {
      color: theme.colorPrimary,
      marginBottom: 1,
      marginTop: 16,
    },
    link: {
      color: 'inherit',
      textDecorationColor: colors.stone[400],
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 8,
      bottom: 10,
      right: 20,
      width: 50,
      textAlign: 'right',
      color: 'grey',
    },
  })

type StyleType = ReturnType<typeof buildStyles>

const defaultThemeValues = getDefaultThemeValues<BrusselsTheme>(themeOptions)

const ThemeContext = createContext<{ styles: StyleType; theme: BrusselsTheme }>(
  {
    styles: buildStyles(defaultThemeValues),
    theme: defaultThemeValues,
  },
)
const useTheme = () => useContext(ThemeContext)

interface LinkProps extends PropsWithChildren {
  href?: string
}

const StyledLink: React.FC<LinkProps> = ({ children, href }) => {
  const { styles } = useTheme()

  return href ? (
    <Link href={href} style={styles.link}>
      {children}
    </Link>
  ) : (
    <Text>{children}</Text>
  )
}

interface LinkWithIconProps {
  name: string
  url?: string
  icon?: string
}

const LinkWithIcon: React.FC<LinkWithIconProps> = ({ icon, name, url }) => (
  <View
    style={{
      marginBottom: 3,
      flexDirection: 'row',
      alignItems: 'center',
      gap: 4,
    }}
  >
    {icon && <Image src={icon} style={{ height: 12, aspectRatio: 1 }} />}

    <StyledLink href={url}>{name}</StyledLink>
  </View>
)

const Header: React.FC<{ resume: Resume }> = ({ resume }) => {
  const { theme } = useTheme()

  return (
    <View
      style={{
        alignItems: 'center',
        marginBottom: 28,
        flexDirection: 'row',
      }}
    >
      {resume.basics.image ? (
        <Image
          src={resume.basics.image}
          style={{
            width: theme.imageSize,
            borderRadius: (theme.imageRadius || 0) + '%',
            marginLeft: 36,
            marginRight: 20,
            objectFit: 'cover',
          }}
        />
      ) : (
        <View style={{ width: 36 }} />
      )}
      <View>
        <Text
          style={{
            fontSize: 24,
            fontWeight: 900,
            color: colors.stone[600],
            lineHeight: 1.35,
          }}
        >
          {resume.basics.name}
        </Text>
        {resume.basics.label && (
          <Text style={{ fontSize: 14, color: colors.stone[500] }}>
            {resume.basics.label}
          </Text>
        )}
      </View>
    </View>
  )
}

const Sidebar: React.FC<{ resume: Resume }> = ({ resume }) => {
  const { styles, theme } = useTheme()

  return (
    <View style={styles.side}>
      <Text style={{ marginBottom: 24 }}>{resume.basics.summary}</Text>

      {resume.skills && resume.skills.length && (
        <View>
          <Text style={styles.subheader}>Skills</Text>

          {resume.skills.map((skill, i) => (
            <View key={skill.name} style={{ marginTop: i === 0 ? 0 : 8 }}>
              <View
                style={{
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
                <Text style={styles.title}>{skill.name}</Text>
                <Text>{skill.level}</Text>
              </View>

              <Text>{skill.keywords?.join(', ')}</Text>
            </View>
          ))}
        </View>
      )}

      {resume.languages && resume.languages.length && (
        <View>
          <Text style={styles.subheader}>Languages</Text>

          {resume.languages.map((language) => (
            <View
              key={language.language}
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                columnGap: 3,
              }}
            >
              <Text style={styles.title}>{language.language}</Text>
              <Text>{language.fluency}</Text>
            </View>
          ))}
        </View>
      )}

      {resume.certificates && resume.certificates.length && (
        <View>
          <Text style={styles.subheader}>Certificates</Text>

          {resume.certificates.map((cert) => (
            <View key={cert.issuer + cert.name}>
              <Text style={styles.title}>{cert.name}</Text>

              <Text>
                {cert.url ? (
                  <StyledLink href={cert.url}>{cert.issuer}</StyledLink>
                ) : (
                  cert.issuer
                )}
              </Text>

              <Text style={{ color: theme.colorTextSecondary }}>
                {cert.date}
              </Text>
            </View>
          ))}
        </View>
      )}

      {resume.education && resume.education.length && (
        <View>
          <Text style={styles.subheader}>Education</Text>

          {resume.education.map((education, i) => (
            <View
              style={{ marginTop: i === 0 ? 0 : 8 }}
              key={education.institution + education.studyType}
            >
              <Text style={styles.title}>
                {education.studyType}
                &nbsp;&middot;&nbsp;
                {education.area}
              </Text>

              <StyledLink href={education.url}>
                {education.institution}
              </StyledLink>

              <Text style={{ color: theme.colorTextSecondary }}>
                {education.startDate} - {education.endDate}
              </Text>
            </View>
          ))}
        </View>
      )}

      {resume.basics.profiles && resume.basics.profiles.length && (
        <View>
          <Text style={styles.subheader}>Profiles</Text>

          {resume.basics.profiles.map((profile) => {
            const urlName = beautifyUrl(profile.url)
            const logo = getLogoByURL(urlName)

            return (
              <LinkWithIcon
                icon={logo || '/icons/link.png'}
                url={profile.url}
                name={urlName}
                key={profile.url}
              />
            )
          })}
        </View>
      )}

      <View style={{ marginBottom: 12 }}>
        <Text style={styles.subheader}>Contacts</Text>

        {resume.basics.email && (
          <LinkWithIcon
            name={resume.basics.email}
            url={`mailto:${resume.basics.email}`}
            icon="/icons/email.png"
          />
        )}

        {resume.basics.phone && (
          <LinkWithIcon
            name={resume.basics.phone}
            url={`tel:${resume.basics.phone.replaceAll(' ', '')}`}
            icon="/icons/phone.png"
          />
        )}

        {resume.basics.url && (
          <LinkWithIcon
            name={beautifyUrl(resume.basics.url)}
            url={resume.basics.url}
            icon="/icons/link.png"
          />
        )}

        {resume.basics.location &&
          Object.keys(resume.basics.location).length && (
            <LinkWithIcon
              icon="/icons/location.png"
              name={resume.basics.location.city!}
            />
          )}
      </View>
    </View>
  )
}

const WorkExperience: React.FC<{ work: Work | Project }> = ({ work }) => {
  const { theme } = useTheme()

  return (
    <View wrap={false} style={{ marginTop: 20 }}>
      <View style={{ flexDirection: 'row', alignItems: 'flex-start', gap: 8 }}>
        {/* Logo */}
        {work.logo ? (
          <Image
            src={work.logo}
            style={{ width: 28, height: 28, objectFit: 'contain' }}
          />
        ) : (
          <View style={{ width: 28, height: 28 }} />
        )}

        {/* Position and company */}
        <View style={{ width: 250 }}>
          <Text style={{ color: colors.stone[600], fontWeight: 700 }}>
            {'position' in work && work.position && `${work.position} @ `}
            <StyledLink href={work.url}>{work.name}</StyledLink>
          </Text>

          <Text style={{ marginBottom: 4, color: theme.colorTextSecondary }}>
            {work.summary}
          </Text>
        </View>

        {/* Dates and location */}
        <View
          style={{
            flexShrink: 0,
            color: theme.colorTextSecondary,
            flexDirection: 'column',
            alignItems: 'flex-end',
            width: 90,
          }}
        >
          <Text>
            {work.startDate} - {work.endDate}
          </Text>

          {'location' in work && work.location && <Text>{work.location}</Text>}
          {'type' in work && work.type && <Text>{work.type}</Text>}
        </View>
      </View>

      <View style={{ marginLeft: 36 }}>
        {work.highlights?.map((line) => (
          <Text style={{ marginTop: 4, flexDirection: 'row' }} key={line}>
            <Text>&bull;</Text>
            <Text>{line}</Text>
          </Text>
        ))}

        {'keywords' in work && work.keywords && (
          <Text style={{ color: theme.colorTextSecondary }}>
            {work.keywords.join(', ')}
          </Text>
        )}
      </View>
    </View>
  )
}

const Brussels: React.FC<ResumeTemplateProps<BrusselsTheme>> = ({
  resume,
  theme,
}) => {
  const styles = buildStyles(theme)

  return (
    <ThemeContext.Provider value={{ styles, theme }}>
      <Document>
        <Page size="A4" style={styles.page}>
          {/* Main */}
          <View style={styles.main}>
            <Header resume={resume} />

            {resume.work.map((work) => (
              <WorkExperience work={work} key={work.name + work.position} />
            ))}

            {resume.projects?.length && (
              <Text
                style={{
                  color: theme.colorPrimary,
                  fontSize: 14,
                  fontWeight: 700,
                  marginLeft: 36,
                  marginTop: 20,
                }}
              >
                Projects
              </Text>
            )}

            {resume.projects?.map((project) => (
              <WorkExperience
                work={project}
                key={project.name + project.entity}
              />
            ))}
          </View>

          {/* Sidebar */}
          <Sidebar resume={resume} />

          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              totalPages > 1 && `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>
      </Document>
    </ThemeContext.Provider>
  )
}

Brussels.displayName = 'Brussels'

export default Brussels
