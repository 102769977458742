import { createRef, useCallback, useEffect, useRef } from 'react'
import { Pane } from 'tweakpane'

import { ThemeData, ThemeOptions } from './types'
import { useResume } from './context/resume-context'

interface Props {
  theme: ThemeOptions
}

export const getThemeDefaultValues = (theme: ThemeOptions) => {
  const defaultValues: Record<string, string | number> = {}

  Object.entries(theme).forEach(([key, option]) => {
    defaultValues[key] = option.defaultValue as string | number
  })

  return defaultValues
}

const initDesigner = (
  container: HTMLElement,
  theme: ThemeOptions,
  initialData: ThemeData,
  onChange: (key: string, value: string | number) => void,
) => {
  const pane = new Pane({
    expanded: true,
    container,
  })

  Object.entries(theme).forEach(([key, option]) => {
    pane.addBinding(initialData, key, option).on('change', (event) => {
      onChange(key, event.value)
    })
  })
}

const ThemeDesigner: React.FC<Props> = ({ theme }) => {
  const designerInstalled = useRef(false)
  const styleEditorContainer = createRef<HTMLDivElement>()
  const { resume, setResume } = useResume()

  const onThemeChange = useCallback(
    (key: string, value: string | number) => {
      setResume((prev) => ({
        ...prev,
        theme: {
          ...(prev.theme || {}),
          [key]: value,
        },
      }))
    },
    [setResume],
  )

  useEffect(() => {
    if (styleEditorContainer.current && !designerInstalled.current) {
      designerInstalled.current = true

      const initialData = {
        // Theme default values
        ...getThemeDefaultValues(theme),
        // Resume theme values
        ...(resume.theme || {}),
      }

      initDesigner(
        styleEditorContainer.current,
        theme,
        initialData,
        onThemeChange,
      )
    }
  }, [
    onThemeChange,
    resume,
    resume.theme,
    setResume,
    styleEditorContainer,
    theme,
  ])

  return <div ref={styleEditorContainer} />
}

export default ThemeDesigner
