import { RouterContext } from '@/routes/__root'
import { ParsedLocation, redirect } from '@tanstack/react-router'

interface BeforeLoadFunction {
  context: RouterContext
  location: ParsedLocation
}

export const checkAuthentication = ({
  context,
  location,
}: BeforeLoadFunction) => {
  if (!context.auth) {
    throw redirect({
      to: '/login',
      search: {
        redirect: location.href,
      },
    })
  }
}
